import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

// import Meta from '../components/Meta.js'
import Layout from '../components/Layout.js'
import Media from '../components/Media.js'
import Kontakt from '../components/Kontakt.js'
import Footer from '../components/Footer.js'
import News from '../components/News.js'
import Project from '../components/Project.js'
import Termine from '../components/Termine.js'
import Teilnehmer from '../components/Teilnehmer.js'

export default ({ data, location }) => {
  console.log('liga.js data:', data)
  const showTeilnehmer =
    data.teilnehmer && data.teilnehmer.data.bereichVisible === 'Bereich Teilnehmer an'

  return (
    <Layout location={location} fullScreen={false} showTeilnehmer={showTeilnehmer}>
      {/* <Meta site={get(data, 'site.meta')} /> */}
      <News news={data?.news?.nodes || []} />
      <Project data={data.project.data} />
      {showTeilnehmer && (
        <Teilnehmer
          teilnehmerData={data?.teilnehmer?.data || {}}
          teilnehmerliste={data?.teilnehmerliste?.nodes || []}
        />
      )}
      <Termine data={data.termine.data} />
      <Media
        location={location}
        archiv={data?.archiv?.nodes || []}
        downloads={data?.downloads?.nodes || []}
        erklaerungen={data?.erklaerungen?.nodes || []}
      />
      <Kontakt data={data.kontakt.data} />
      <Footer />
    </Layout>
  )
}

export const ligaQuery = graphql`
  query ligaQuery {
    news: allPrismicNewsLiga(sort: { fields: data___date, order: DESC }) {
      nodes {
        id
        first_publication_date
        data {
          headline {
            text
          }
          subline_rich {
            text
            html
          }
          date
          image {
            url
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
            fixed(width: 1600, height: 900) {
              ...GatsbyPrismicImageFixed
            }
          }
        }
      }
    }
    project: prismicProjektLiga {
      data {
        main_headline {
          text
        }
        headline_first: headline_liga {
          text
        }
        text_first: text_liga {
          text
          html
        }
        headline_phasen {
          text
        }
        body {
          ... on PrismicProjektLigaBodyPhase {
            id
            primary {
              phase_bild {
                url
                fluid(maxWidth: 600, maxHeight: 400) {
                  ...GatsbyPrismicImageFluid
                }
              }
              phase_titel {
                text
              }
              phase_text {
                text
                html
              }
            }
          }
        }
        headline_preise {
          text
        }
        text_preise {
          text
        }
        icon_preise {
          url
        }
      }
    }
    teilnehmer: prismicTeilnehmer {
      data {
        punkteVisible: anzeige_punkte_liga
        bereichVisible: bereich_liga
      }
    }
    teilnehmerliste: allPrismicTeilnehmerBodyLigaListOfArticles {
      nodes {
        id
        primary {
          teilnehmer_name {
            text
          }
          teilnehmer_punkte {
            text
          }
        }
      }
    }
    termine: prismicTermineLiga {
      data {
        board_headline {
          text
        }
        body {
          ... on PrismicTermineLigaBodyTafelspalte {
            id
            primary {
              line1 {
                text
              }
              line2 {
                text
              }
            }
          }
        }
      }
    }

    downloads: allPrismicMediaBody1File {
      nodes {
        id
        primary {
          beschreibung {
            text
          }
          file {
            url
          }
        }
      }
    }
    kontakt: prismicPageSettings {
      data {
        headline: kontakt_liga_headline {
          text
        }
        text: kontakt_liga_text {
          text
          html
        }
      }
    }
  }
`
